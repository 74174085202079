/** @format */

import { bindable } from 'aurelia-framework';
import uiService from 'services/uiService';
import telemetryService from 'services/api/telemetryService';

import './journey-map.scss';

export class JourneyMap {
  @bindable journey;

  constructor() {
    this.mapProvider = uiService.mapProvider;
    this.mapTelemetry = null;
    this.mapTelemetryOptions = {
      tooltips: false
    };

    this.qMapReady = new Promise(resolve => {
      this.qMapResolve = resolve;
    });

    this.journey = null;
  }

  journeyChanged(journey) {
    if (journey) {
      const { assetId, startGpsTimestamp, endGpsTimestamp } = journey;
      telemetryService
        .getPositions(assetId, startGpsTimestamp, endGpsTimestamp)
        .then(positions => {
          this.mapTelemetry = positions;
        });
    }
  }

  onMapReady = () => {
    this.qMapResolve();
  };
}
