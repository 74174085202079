/** @format */

import { inlineView } from 'aurelia-framework';
import PLATFORM from 'aurelia-pal';

@inlineView('<template><router-view containerless></router-view></template>')
export class ManagementJourneys {
  configureRouter(config) {
    config.map([{
        route: '',
        name: 'journeys',
        title: '',
        moduleId: PLATFORM.moduleName('./manage-journeys-list')
      },
      {
        route: ':id',
        name: 'journeys-asset',
        title: 'Asset journeys',
        moduleId: PLATFORM.moduleName('./manage-journeys-asset/manage-journeys-asset')
      }
    ]);
  }
}
