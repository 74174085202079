/** @format */
import { DialogController } from 'aurelia-dialog';

import './manage-journey-details.scss';

export class ManageJourneyDetails {
  static inject() {
    return [DialogController];
  }

  constructor(_DialogController) {
    this.dialogController = _DialogController;
  }

  activate(journey) {
    this.journey = journey;
  }

  onConfirm() {
    this.dialogController.ok();
  }
}
