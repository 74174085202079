/** @format */
// import
import { DateTimeUtils as DTU } from '@fonix/web-utils';
import { Router } from 'aurelia-router';
import { DialogService } from 'aurelia-dialog';
import { bindable } from 'aurelia-framework';

import { ManageJourneyDetails } from '../manage-journey-details/manage-journey-details';

// import assetsService from 'services/api/assetsService';
import telemetryService from 'services/api/telemetryService';
import './manage-journeys-asset.scss';

export class ManageJourneysAsset {

  @bindable searchValue;

  static inject() {
    return [Router, DialogService];
  }

  constructor(router, dialogService) {
    this.router = router;
    this.dialogService = dialogService;

    this.asset = null;
    this.journeys = [];
    this.loading = false;
    this.dates = null;
    this.searchValue = '';
  }

  activate(params, cfg) {
    let { asset, dates } = cfg.settings;

    //go back for now
    if (!asset) {
      setTimeout(() => this.navigateBack(), 0);
      return;
    }

    let qAsset = Promise.resolve([asset]);
    // asset ? Promise.resolve([asset]) : assetsService.get(params.id);

    this.loading = true;
    this.dates = dates;

    qAsset
      .then(([asset]) => {
        this.asset = asset;
        return telemetryService.getJourneys(asset.id, dates.from, dates.to, true);
      })
      .then(journeys => {
        this.journeys = journeys;
        this.loading = false;
      });
  }

  handleJourneyClick = journey => {
    //inject assetName as individual journey from GET with assetId do not come with asset name
    journey.assetName = this.asset.name;
    this.dialogService.open({
      viewModel: ManageJourneyDetails,
      model: journey
    });
  };

  onClose = () => {
    this.navigateBack();
  };

  navigateBack = () => {
    let queryString = {};

    if (this.dates) {
      let from = DTU.format(this.dates.from, 'x');
      let to = DTU.format(this.dates.to, 'x');
      queryString = { dates: { from, to } };
    }
    this.router.navigateToRoute('journeys', queryString);
  };
}
