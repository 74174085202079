import PLATFORM from 'aurelia-pal';

export class Management {
  constructor() {

  }

  configureRouter(config, router) {
    this.router = router;


    // const setupRoutes = () => {
    const routes = [];

    routes.push(this.newRoute('journeys', 'Journeys', PLATFORM.moduleName('pages/management/pages/journeys/management-journeys')))
    // routes.push(this.newRoute('costs'))

    routes.push({
      route: '',
      redirect: 'journeys'
    })
    config.map(routes);
    // }


    // setupRoutes();
    config.mapUnknownRoutes(() => {
      router.navigateToRoute('management');
    });
  }

  newRoute(name, title, moduleId) {
    return {
      route: name,
      name,
      title: title,
      moduleId: moduleId || `./pages/${name}/management-${name}`,
      nav: true
    };
  }

  goTo(routeName) {
    let route = this.router.routes.find(x => x.name === routeName);
    this.enabledAccountFilter =
      (route && route.settings.enabledAccountFilter) || false;
    this.router.navigateToRoute(routeName);
  }

  get pageSectionItems() {
    return this.router.navigation.filter(n => !n.config.subsection);
  }
}
