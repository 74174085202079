/** @format */
import telemetryService from 'services/api/telemetryService';
import { DateTimeUtils as DTU } from '@fonix/web-utils';
import { Router } from 'aurelia-router';

import './manage-journeys-list.scss';

export class ManageJourneysList {  
  static inject() {
    return [Router];
  }
  constructor(Router) {
    this.router = Router;
    this.route = {};
    this.assetsJourneys = [];
    this.loading = false;

    this.sortProperties = {
      field: 'assetName',
      reverse: false
    }

    this.dtFrom = DTU.startOf(new Date());
    this.dtTo = new Date();

    this.pickerOpt = {
      maxDate: DTU.endOf(new Date()),
      maxDays: 6
    }
  }

  activate(params, config) {
    this.route = config
    this.router = config.navModel.router;

    this.removeQS();

    if (params.dates) {
      this.dtTo = DTU.toLocal(+params.dates.to);
      this.dtFrom = DTU.toLocal(+params.dates.from);
    }
    this.loadJourneys(this.dtFrom, this.dtTo);
  }

  loadJourneys = (dtFrom, dtTo) => {
    //pass string version to child component to avoid trigerring unnecessary changes
    this.loading = true;
    telemetryService.getJourneys(null, dtFrom, dtTo, true).then(trips => {
      this.assetsJourneys = trips;
      this.loading = false;
    });
  };

  handleItemClick = item => {
    this.goToDetails(item);
  };

  handleDateRangeChanged = ({ from, to }) => {
    this.dtFrom = from;
    this.dtTo = to;
    this.loadJourneys(from, to);
  };

  //We have a router between list and details
  //We use QS to remeber the selected date from details page
  //so when the uer comes back the date selected is the same
  //when we read the date from QS we can remove the date (for now)
  removeQS() {
    //update QS
    const idxQs = window.location.hash.indexOf('?');
    if (idxQs > -1) {
      // const QS = window.location.hash.substring(idxQs);
      const nonQS = window.location.hash.substring(0, idxQs);
      window.location.hash = nonQS;
    }
  }

  goToDetails(item) {
    let route = 'journeys-asset';
    if (item.assetId) {
      let asset = { name: item.assetName, id: item.assetId };
      let data = { asset, dates: { from: this.dtFrom, to: this.dtTo } };

      this.router.routes.find(x => x.name === route).settings = data;
      this.router.navigateToRoute(route, { id: asset.id });
    }
  }
}
