/** @format */

import { bindable, computedFrom } from 'aurelia-framework';
import telemetryService from 'services/api/telemetryService';

const ITEM_HEIGHT = '60px';
const CONTAINER_PADING = '1rem 0';
const OVERFLOW = 'auto';
const CONTEXT_ACTIONS = {
  setAsParsonal: 'setAsParsonal',
  setAsBussiness: 'setAsBussiness'
};

const CONTEXT_MENU_ITEMS = [
  {
    name: 'set_personal',
    action: CONTEXT_ACTIONS.setAsParsonal,
    showWhen: j => !j.isPersonal
  },
  {
    name: 'set_bussiness',
    action: CONTEXT_ACTIONS.setAsBussiness,
    showWhen: j => j.isPersonal
  }
];

export class JourneysGrid {
  @bindable journeys;
  @bindable loading;
  @bindable onJourneyClick;
  @bindable isScrolling;

  constructor() {
    this.tableRowCss = { height: ITEM_HEIGHT };
    this.tableContainerCss = { padding: CONTAINER_PADING, 'overflow-x': OVERFLOW };

    this.showContextMenu = false;
    this.conextMenuCss = { top: 0 };

    this.contextItems = [];
    this.contextJourney = null;

    this.isScrolling = false;
  }

  @computedFrom('journeys')
  get hasDriver() {
    if (!this.journeys || !this.journeys.length) return false;

    return this.journeys.find(j => !!j.driver);
  }

  attached() {
    document.addEventListener('click', this.onDocumentClick);
  }

  detached() {
    document.removeEventListener('click', this.onDocumentClick);
  }

  onDocumentClick = () => {
    if (this.showContextMenu) this.showContextMenu = false;
  };

  toggleContextMenu = e => {
    this.showContextMenu = !this.showContextMenu;
    e.stopImmediatePropagation();
  };

  handleRowClick = (e, journey) => {
    if (this.onJourneyClick) {
      this.onJourneyClick(journey);
    }

    e.stopPropagation();
  };

  handleContextClick = (e, index, journey) => {
    this.conextMenuCss = { top: `calc(${ITEM_HEIGHT} * ${index + 1})` };
    this.contextJourney = journey;
    this.contextItems = CONTEXT_MENU_ITEMS.filter(ca => ca.showWhen(journey));
    this.toggleContextMenu(e);

    e.stopPropagation();
  };

  handleContextItemClick = e => {
    if (!e || !e.target) return;

    const journey = this.contextJourney;
    const action = e.target.dataset.action;

    //todo refactor
    switch (action) {
      case CONTEXT_ACTIONS.setAsParsonal:
      case CONTEXT_ACTIONS.setAsBussiness:
        this.togglePersonal(journey);
        break;
      default:
        break;
    }
  };

  handleJourneyLabelClick = (e, index, journey) => {
    this.togglePersonal(journey);
    e.stopPropagation();
  }

  togglePersonal = journey => {
    const isBusiness = !journey.isPersonal;
    telemetryService
      .markTripPersonal(journey.id, isBusiness)
      .then(udpdatedJourney => {
        //update list item
        Object.assign(journey, udpdatedJourney);
      })
      .catch(() => {});
  };


  onScroll(e) {
    if (e.target.scrollTop && e.target.scrollTop > 0) {
      this.isScrolling = true;
    } else {
      this.isScrolling = false;
    }
  }
}
